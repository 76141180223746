import Vue from 'vue'
import Router from 'vue-router'
import Constants from './constants'

Vue.use(Router)

var routes = []

if(process.env.VUE_APP_TYPE === 'backend') {

    routes = [

        { name: 'Login', path: '/login', component: () => import('@/components/backend/page/Login') },

        { name: 'Dashboard', path: '/', component: () => import('@/components/backend/page/Dashboard'), meta: { auth: true } },

        { name: 'Settings', path: '/settings', component: () => import('@/components/backend/page/Settings'), meta: { auth: true, api: 'settings', section: 'Settings' } },

        { name: 'Logout', path: '/login', component: () => import('@/components/backend/page/Logout'), meta: { auth: true } },

        { name: 'Activity', path: '/activity', component: () => import('@/components/backend/page/Activity'), meta: { auth: true, api: 'activity', section: 'Activity' } },

        { name: 'Users', path: '/users', component: () => import('@/components/backend/page/Users'), meta: { auth: true, api: 'users', section: 'Users', editName: 'Users.Edit' } },
        { name: 'Users.Edit', path: '/users/:id', component: () => import('@/components/backend/page/User'), meta: { auth: true, api: 'user', noun: 'user', section: 'Users' } },

        { name: 'Forms', path: '/forms', component: () => import('@/components/backend/page/Forms'), meta: { auth: true, api: 'forms', section: 'Forms',  editName: 'Forms.Edit' } },
        { name: 'Forms.Edit', path: '/forms/:id', component: () => import('@/components/backend/page/Form'), meta: { auth: true, api: 'form', noun: 'form', section: 'Forms' } },

        { name: 'Images', path: '/images', component: () => import('@/components/backend/page/Assets'), meta: { auth: true, api: 'images', noun: 'image', section: 'Images', editName: 'Images.Edit' } },
        { name: 'Images.Edit', path: '/images/:id', component: () => import('@/components/backend/page/Asset'), meta: { auth: true, api: 'asset', noun: 'image', section: 'Images' } },

        { name: 'Files', path: '/files', component: () => import('@/components/backend/page/Assets'), meta: { auth: true, api: 'files', noun: 'file', section: 'Files', editName: 'Files.Edit' } },
        { name: 'Files.Edit', path: '/files/:id', component: () => import('@/components/backend/page/Asset'), meta: { auth: true, api: 'asset', noun: 'file', section: 'Files' } },

        { name: 'Pages', path: '/pages', component: () => import('@/components/backend/page/Pages'), meta: { auth: true, api: 'pages', section: 'Pages', editName: 'Pages.Edit' } },
        { name: 'Pages.Edit', path: '/pages/:id', component: () => import('@/components/backend/page/Page'), meta: { auth: true, api: 'page', noun: 'page', section: 'Pages' } },

        { name: 'Events', path: '/events', component: () => import('@/components/backend/page/Events'), meta: { auth: true, api: 'events', section: 'Events', editName: 'Events.Edit' } },
        { name: 'Events.Edit', path: '/events/:id', component: () => import('@/components/backend/page/Page'), meta: { auth: true, api: 'page', noun: 'event', section: 'Events', defaultType: Constants.pageType.event } },

        { name: 'News', path: '/news', component: () => import('@/components/backend/page/News'), meta: { auth: true, api: 'news', section: 'News', editName: 'News.Edit' } },
        { name: 'News.Edit', path: '/news/:id', component: () => import('@/components/backend/page/Page'), meta: { auth: true, api: 'page', noun: 'news', section: 'News', defaultType: Constants.pageType.news } },

        { name: 'Menu', path: '/menu', component: () => import('@/components/backend/page/Menu'), meta: { auth: true, api: 'menu', section: 'Menu', editName: 'Menu.Edit' } },
        { name: 'Menu.Edit', path: '/menu/:id', component: () => import('@/components/backend/page/Page'), meta: { auth: true, api: 'page', noun: 'page', section: 'Menu', defaultType: Constants.pageType.internal } },

    ]

} else {

    routes = []

}

export default new Router({
	mode: 'history',
	base: '/',
	routes: routes
})