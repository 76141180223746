export default {

    notifyEnabled: {
        0: 'Disabled',
        1: 'Enabled'
    },

    assetType: {
        image: 1,
        file: 2
    },

    userStatus: {
        active: 1,
        disabled: 2
    },

    userStatusName: {
        1: 'Active',
        2: 'Disabled'
    },

    redirectName: {
        0: 'No',
        1: 'Yes'
    },

    pageStatus: {
        public: 1,
        offline: 2
    },

    pageStatusName: {
        1: 'Public',
        2: 'Offline'
    },

    pageType: {
        home: 1,
        standalone: 1,
        internal: 2,
        event: 3,
        news: 4
    },

    pageTypeName: {
        1: 'Standalone',
        2: 'Menu',
        3: 'Event',
        4: 'News'
    },

    blockType: {
        textImage1: 1,
        textImage2: 2,
        textImage3: 3,
        buttons: 4,
        text1: 5,
        text2: 6,
        category1: 7,
        category2: 8,
        team: 9,
        divider1: 10,
        divider2: 11,
        form: 12,
        resources: 13,
        gallery: 14,
        banner: 15,
        embed: 16,
        news: 17,
        timeline: 18,
        locations: 19,
        events: 20,
        textImage4: 21,
        homeFeature: 22,
        timelineHead: 23,
        calendar: 24,
        head: 26,
        foot: 27
    },

    blockTypeName: {
        1: 'Text & Image 1',
        2: 'Text & Image 2',
        3: 'Text & Image 3',
        4: 'Buttons',
        5: 'Text 1',
        6: 'Text 2',
        7: 'Category 1',
        8: 'Category 2',
        9: 'Team',
        10: 'Divider 1',
        11: 'Divider 2',
        12: 'Form',
        13: 'Resources',
        14: 'Gallery',
        15: 'Banner',
        16: 'Embed',
        17: 'News',
        18: 'Timeline Group',
        19: 'Locations',
        20: 'Events',
        21: 'Text & Image 4',
        22: 'Home Feature',
        23: 'Timeline Head',
        24: 'Calendar'
    },

    inputType: {
        textbox: 1,
        textarea: 2,
        select: 3,
        checkbox: 4
    },

    inputTypeName: {
        1: 'Text Box',
        2: 'Text Area',
        3: 'Select List',
        4: 'Check Box'
    }

}