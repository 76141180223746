<template>

<div class="form inner">

    <div class="form-text">{{ item.text }}</div>

    <div class="form-inputs">

        <app-backend-icon icon="loading" v-if="is.loading" class="form-inputs-loading" />

        <template v-for="input in inputs">

            <com-textbox :input="input" v-if="input.type === $constants.inputType.textbox" :key="input.id" />
            <com-textarea :input="input" v-if="input.type === $constants.inputType.textarea" :key="input.id" />
            <com-select :input="input" v-if="input.type === $constants.inputType.select" :key="input.id" />
            <com-checkbox :input="input" v-if="input.type === $constants.inputType.checkbox" :key="input.id" />

        </template>

    </div>

    <div class="form-button">{{ item.button }}</div>

</div>

</template>

<script>

import comTextbox from './form/Textbox'
import comTextarea from './form/Textarea'
import comSelect from './form/Select'
import comCheckbox from './form/Checkbox'

export default {

    props: ['item'],

    components: {
        'com-textbox': comTextbox,
        'com-textarea': comTextarea,
        'com-select': comSelect,
        'com-checkbox': comCheckbox
    },

    data: function() {
    
        return {
            is: {
                loading: true
            },
            inputs: []
        }

    },

    created: function() {
    
        this.load()
    
    },

    methods: {
    
        load: function() {
        
            this.$api.get('../frontend/form', {
                form: this.item.form
            }).then(function(response) {
            
                this.$vueset(this, 'inputs', response.inputs)

                this.is.loading = false

            }.bind(this))
        
        }
    
    }

}

</script>

<style scoped>

.form {
    max-width: 640px;
    padding: 64px 0px;
    margin: 0px auto;
}

.is-tablet .form {
    padding: 40px 0px;
}

.is-mobile .form {
    padding: 20px 10px;
}

.form-text {
    color: #45464B;
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    text-align: center;
}

.is-tablet .form-text {
    font-size: 14px;
    line-height: 24px;
}

.is-mobile .form-text {
    font-size: 13px;
    line-height: 24px;
}

.form-inputs {
    padding: 40px 0px;
    min-height: 64px;
}

.is-mobile .form-inputs {
    padding: 20px 0px;
}

.form-inputs-loading {
    font-size: 24px;
    color: #00583D;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.form-button {
    border: 1px solid #00573C;
    border-radius: 8px;
    text-transform: uppercase;
    color: #00573C;
    font-weight: 500;
    width: 200px;
    margin: 0px auto;
    text-align: center;
    font-size: 16px;
    line-height: 70px;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAABLCAMAAADXnC7SAAAAP1BMVEUAAAAAWD0AWDwAWT0AVzwAVz0AYEAAWD0AWD0AWjoAWD0AVz4AWD0AWT0AWEAAWD0AWT0AVz0AWD0AVz4AWD11/yeTAAAAFHRSTlMAwEA/l/AQgGAw4M+gcCDQUK+wb6KSSSIAAAFPSURBVEjH7ZbbjoMgEIbBylk88v7PuvgbpAii7SZ7sfG7aArzDQyDiZIScnLOLJzcQ2oHmLiT0cDeYMuVzSeIoufbLqapL85WaVBbXRiIyuLtVnY6pvxMNwj3JKL0uoA615k8TM5IONOHPGLLCf16uK5UqkRCafWOgDsJXarnCYanRUKvJUwk0kCvsF6EjcVr3xlOagz+CPvFCAxqoH00/Pebje+xiYJJHU4o997Mx14BSt6gzumteh9RSaToq7CBRSD1hQfThQ00qk8DxHP0R9SB05IrHzC/L9LoPX/GTIusuh+vCVF55cenBtHmE59d+RH47ktfvQIm+Oa1c/RHl4Bogk39LvdZMkNTnybBFu2u+vg95T/6EW3DayxCL/sPPfq/u1/Cm50h+EOc458+n4//+I//+I//9/7yArXvARD8HPgZX/mizRGrb9sC5AfRP0w/lq0OrwAAAABJRU5ErkJggg==');
    background-repeat: no-repeat;
    background-position: 20px 50%;
    background-size: auto 41px;
}

.is-tablet .form-button {
    font-size: 14px;
}

.is-mobile .form-button {
    background-size: 18px auto;
    font-size: 13px;
    line-height: 54px;
}

.form-button:hover {
    color: #fff;
    background-color: #00573C;
}

</style>
